import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github, vsDark, oceanicNext } from "@code-surfer/themes";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  github: github,
  vsDark: vsDark,
  oceanicNext: oceanicNext,
  React: React
};